<template>
  <router-link class="link" :to="to">{{ label }}</router-link>
</template>

<script>
export default {
  props: ["to", "label"],
  mounted(){}
};
</script>

<style scoped>
.link {
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}
</style>