<template>
  <div class="input-wrapper">
    <input
      :class="{ filled: value, input: true, error: error }"
      :type="type"
      v-model="InputVal"
      v-mask="mask"
      :min="min"
      :max="max"
      :id="id"
      :maxlength="maxlength"
      @keyup.enter="keyup"
    />
    <label class="label" :for="id">
      {{ label }}
    </label>
    <button @click.prevent="$emit('errorStatus')" :class="{ closeError: true, hidden: !error}">X</button>
    <p v-if="error" class="erro-msg"> {{ errorMsg ? errorMsg : 'Preencha corretamente'}} </p>
  </div>
</template>

<script>
export default {
  props: ["id", "label", "disabled",'type', "value", "error", "click", "min", "max", "mask", "keyup", "errorMsg","maxlength"],
  data() {
    return {};
  },
  computed: {
    InputVal: {
      get(){
        return this.value;
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
};
</script>

<style scoped>
.input-wrapper{
  position: relative;
  flex: 1;

}

.input {
  background: #ececec;
  border: none;
  height: 64px;
  width: 100%;
  border-radius: 16px;
  padding: 24px;
  padding-top: 40px;
  font-size: 18px;
}

.label {
  color: #8d8d8d;
  font-size: 18px;
  position: absolute;
  top: 22px;
  left: 24px;
  transition: 0.3s;
}

.input:focus {
  border: 2px solid #004d44;
  background: #fcfcfc;
}

.input:focus + .label,
.filled + .label,
input[type="date"] + .label  {
  top: 9px;
}

.input:disabled,
.input:disabled + .label {
  opacity: 0.5;
}

.input.error {
  border: 2px solid #ed2e7e;
}

.input.error + .label {
  color: #ed2e7e;
}

.closeError {
  position: absolute;
  right: 25px;
  top: 25px;
  background: transparent;
  color: #ed2e7e;
  border: none;
  cursor: pointer;
}

.hidden {
  display: none;
}

.erro-msg {
  text-align: left !important;
  color: #C30052;
  font-size: 16px;
  letter-spacing: 0.2px;
  margin-top: 9px;
}
</style>