<template>
  <button 
    class="button btn-filled" 
    :disabled="disabled"
    @click.prevent="onClick"
  >
      <div v-if="isLoading">
          <div class="loading"></div>
      </div>
      <div v-else>
        {{label}}
      </div>
  </button>
</template>

<script>
export default {
    props: ['label', 'isLoading', 'disabled', 'onClick']
}
</script>

<style scoped>
    @keyframes loading {
        from {
            transform: rotate(0deg);
        }
        to {
            transform:  rotate(360deg);
        }
    }

    .button {
        background: #FF7FA8 !important;
        border-radius: 40px !important;
        border: none !important;
        width: 100% !important;
        min-height: 64px !important;
        font-size: 16px;
        color: #fff !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button:focus {
        box-shadow: 3px 3px 3px #F2CBD7 !important;
    }

    .loading {
        border: 2px solid #F7F7FC !important;
        border-bottom: 2px solid transparent !important;
        width: 20px;
        height: 20px;
        animation: loading 1s linear infinite;
        border-radius: 50%;
    }
    
    .button:disabled {
        background: #F2CBD7 !important;
    }
</style>